import React, { useContext } from 'react';
import { ButtonOutlined } from '@components';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './Higher.style';
import { Route } from '@interfaces';
import { SaveByRokinContext } from '@context';
import { Player } from 'video-react';
import { useAuth } from '@hooks';
import { navigate } from 'gatsby';

const HigherLanding: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');
  const { isAlreadyCreated } = useContext(SaveByRokinContext);

  const { isLoggedIn } = useAuth();

  return (
    <section>
      <div className={classes.background} />
      <div className={classes.container}>
        <h1 className={classes.title}>Tus finanzas en simple</h1>
        <h2 className={classes.subtitle}>
          Cada decisión que tomas tiene un impacto financiero. Rokin es{' '}
          <span>
            la forma más fácil de ordenar tus finanzas, controlar tus gastos,
            aumentar tu ahorro y no volver a pagar de más.
          </span>
        </h2>
        {/*  <div className={classes.topContact}>
          <span>Sé el primero </span>
          en acceder a los beneficios que Rokin tiene para ti y
          <span> participa por un Alexa Echo Dot.</span>
        </div> */}
        <div className={classes.contact}>
          <ButtonOutlined
            onClick={() =>
              isLoggedIn ? navigate(Route.finance) : navigate(Route.login)
            }
            text='Empezar'
          />
        </div>
        {isAlreadyCreated && (
          <section className={classes.mensaje}>
            ¡Ya estás inscrito para el lanzamiento de Rokin!
          </section>
        )}
        <div className={classes.img}>
          <Player
            loop={true}
            playsInline
            muted
            autoPlay={true}
            src='https://rokinapp-images.s3.amazonaws.com/videos/Animacion+inicio_alta+resolucion.mp4'
          />
        </div>
      </div>
    </section>
  );
};

export default HigherLanding;
