import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './Security.style';

const SecurityLanding: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');

  return (
    <section className={classes.container}>
      <div className={classes.top}>
        <div>En buenas manos</div>
        <div className='subtitle'>
          Tu<span> seguridad </span>es nuestra
          <span> prioridad</span>
        </div>
      </div>
      <div className={classes.bottom}>
        <img
          src={
            'https://storage.googleapis.com/rokinapp-images/index/seguridad_img%20(1).svg'
          }
          alt='a'
        />
        <div>
          Nuestra misión es ayudarte a recuperar el control de tus finanzas, por
          esto
          <span> nos tomamos tu seguridad y privacidad muy en serio.</span>
        </div>
      </div>
    </section>
  );
};

export default SecurityLanding;
