import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "6rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(500)]: {
      gridTemplateColumns: "100%",
      maxHeight: 560,
    },
  },
  background: {
    background: "#0d3038",
    width: "100%",
    height: "30rem",
    position: "absolute",
    top: 0,
  },
  containerItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "0rem",
    width: "85rem",
    margin: "13rem auto 10rem",
    [theme.breakpoints.down(1240)]: {
      display: "flex",
      flexDirection: "column-reverse",
      position: "relative",
      height: "33rem",
      width: "20rem",
      margin: "5rem auto",
      gap: "5rme",
      zIndex: 2,
      justifyContent: "start",
    },
  },
  invert: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column-reverse",
    },
  },
  title: {
    display: "block",
    width: "32rem",
    fontSize: "2.5rem",
    fontFamily: "Montserrat",
    position: "relative",
    zIndex: 4,
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: {
      fontSize: "1.5rem",
      width: "19rem",
    },
    "& strong": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    display: "block",
    width: "17rem",
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
  },
  img2: {
    width: "auto",
    top: "-3rem",
    position: "absolute",
    zIndex: 1,
    maxHeight: "27rem",
  },
  moreExpensive: {
    "& >img": {
      width: "45rem",
    },
  },
  img: {
    width: "40rem",
    [theme.breakpoints.down(1240)]: {
      position: "absolute",
      top: "3.5rem",
      width: "20rem",
      minWidth: "21rem",
      maxWidth: "21rem",
      zIndex: 1,
      margin: " 0 auto",
    },
  },
  text: {
    display: "block",
    width: "25rem",
    position: "relative",
    zIndex: 2,
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1240)]: { width: "19rem" },
  },
  texts: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    position: "relative",
    fontSize: "1.5rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1240)]: { fontSize: "1rem" },
  },
  textInvert: {
    [theme.breakpoints.up(1240)]: {
      paddingLeft: "7rem",
    },
  },
}));
