import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useStyles } from './ItemsLanding.style';

const ItemsLanding: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 1240px)');

  return (
    <section>
      {item.map((item) => {
        return (
          <div
            className={`${classes.containerItem} ${
              item.id % 2 === 0 && classes.invert
            } ${item.id === 1 && classes.moreExpensive}`}
            key={item.id}
          >
            {isMobile ? (
              <img className={classes.img} src={item.imgMobile} />
            ) : (
              <img className={classes.img} src={item.img} />
            )}
            <div
              className={`${classes.texts} ${
                item.id % 2 === 0 && item.id !== 1 && classes.textInvert
              }`}
            >
              <h2 className={classes.title}>{item.title}</h2>{' '}
              <span className={classes.text}>{item.text}</span>
              {!isMobile && <img className={classes.img2} src={item.img2} />}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ItemsLanding;

const item = [
  {
    id: 0,
    title: (
      <span>
        Tus finanzas en <br />
        <strong>piloto automático</strong>
      </span>
    ),
    text: 'Organiza tu plata, controla tus gastos, maneja tus deudas y potencia tu ahorro, todo desde un solo lugar.',
    imgMobile:
      'https://storage.googleapis.com/rokinapp-images/index/pilotoAutomatico_img%20(1).png',
    img: 'https://storage.googleapis.com/rokinapp-images/index/B1_pilotoAutomatico%20(1)%20(1)%20(1).png',
    img2: 'https://storage.googleapis.com/rokinapp-images/index/01%20(1).png',
  },
  {
    id: 1,
    title: (
      <span>
        No vuelvas a{' '}
        <strong>
          pagar <br /> de más
        </strong>
      </span>
    ),
    text: 'Encuentra la mejor alternativa para tus cuentas, suscripciones, servicios y productos financieros.',
    imgMobile:
      'https://storage.googleapis.com/rokinapp-images/index/B2_pagarDeMas%20(1)%20(1)%20(1).png',
    img: 'https://storage.googleapis.com/rokinapp-images/index/pagarDeMas_img%20(2)%20(1).png',
    img2: 'https://storage.googleapis.com/rokinapp-images/index/02%20(1)%20(1).png',
  },
  {
    id: 2,
    title: (
      <span>
        Plan de acción <strong>a tu medida</strong>
      </span>
    ),
    text: 'Creamos una estrategia con herramientas automatizadas para sacar el mayor potencial de tus finanzas.',
    imgMobile:
      'https://storage.googleapis.com/rokinapp-images/index/B3_aTuMedida%20(1)%20(1)%20(1).png',
    img: 'https://storage.googleapis.com/rokinapp-images/index/aTuMedida_img%20(1).png',
    img2: 'https://storage.googleapis.com/rokinapp-images/index/03%20(1).png',
  },
  {
    id: 3,
    title: (
      <span>
        Encuentra a tu
        <strong>
          {' '}
          personal <br />
          trainer financiero
        </strong>
      </span>
    ),
    text: 'Siempre contarás con acompañamiento personalizado para resolver tus dudas y guiarte en este camino.',
    img: 'https://storage.googleapis.com/rokinapp-images/index/personalTrainer_img%20(1).png',
    imgMobile:
      'https://storage.googleapis.com/rokinapp-images/index/B4_personalTrainer%20(1)%20(1).png',
    img2: 'https://storage.googleapis.com/rokinapp-images/index/04%20(1).png',
  },
];
