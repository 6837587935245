import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: "white",
    marginTop: "6rem",
    position: "relative",
    zIndex: 1,
    fontFamily: "Montserrat",
    minHeight: "55rem",
  },
  background: {
    background: "#06232a",
    width: "100%",
    height: "71.3rem",
    position: "absolute",
    top: 0,
    [theme.breakpoints.down(1240)]: {
      height: "76.7rem",
    },
    [theme.breakpoints.down(880)]: {
      height: "47.75rem",
    },
  },
  img: {
    width: "100%",
    padding: "0",
    zIndex: 900,
    margin: "4.65rem auto 0",
    maxWidth: "930px",
    "& .video-react": {
      paddingTop: "0px!important",
    },
    "& video": {
      margin: "0rem auto 0",
    },
    "& img": {
      margin: "0 auto!important",
      maxWidth: "850px",
      width: "100%",
      [theme.breakpoints.down(880)]: {
        maxWidth: "20rem",
      },
    },
    [theme.breakpoints.down(880)]: {
      paddingTop: "0",
    },
    "&>div": {
      padding: 0,
      "& button, div": {
        display: "none",
      },
    },
  },
  title: {
    fontWeight: 500,
    fontSize: "3.125rem",
    textAlign: "center",
    margin: "8rem 0 1rem",
    [theme.breakpoints.down(1240)]: {
      width: "18rem",
      textAlign: "start",
      margin: "8rem auto 1rem",
      fontSize: "2.625rem",
    },
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 300,
    color: "#00a9c2",
    textAlign: "center",
    width: "50rem",
    margin: "1rem auto 2rem",
    [theme.breakpoints.down(1240)]: {
      width: "18rem",
      textAlign: "start",
      fontSize: "1rem",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  topContact: {
    fontWeight: 300,
    textAlign: "start",
    width: "26rem",
    margin: "1rem auto 1.5rem",
    [theme.breakpoints.down(1240)]: {
      width: "18rem",
      textAlign: "start",
      fontSize: "0.7rem",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  mensaje: {
    margin: "1rem auto 0",
    width: "25rem",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.7rem",
      width: "18rem",
    },
  },
  contact: {
    display: "flex",
    margin: "0 auto",
    justifyContent: "center",
    color: "white",
    "& svg": { color: "white" },
    "& input": { color: "white" },
    "& button": {
      minWidth: "3rem",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginLeft: "1rem",
      height: 50,
      color: "white",
      width: "180px",
      [theme.breakpoints.down(1240)]: {
        marginLeft: "0rem",
      },
      "& span": {
        marginRight: "0",
      },
    },
    "& >div, fieldset": {
      border: "1px solid white",
      width: "21rem",
      "& >div": {
        background: theme.palette.primary.dark,
        borderRadius: 15,
      },
      color: "white",
      borderRadius: 12,
      borderColor: "white!important",
      display: "flex",
      [theme.breakpoints.down(1240)]: {
        width: "15rem",
        textAlign: "start",
        fontSize: "0.75rem",
      },
    },
  },
}));
