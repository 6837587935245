import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
  },
  top: {
    margin: "0 auto 2rem",
    fontWeight: 600,
    display: "grid",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down(1240)]: {
      gap: "1rem",
      margin: "0 auto",
    },
    "& >div": {
      fontSize: "2.625rem",
      [theme.breakpoints.down(1240)]: {
        fontSize: "2.25rem",
      },
    },
    "& .subtitle": {
      textAlign: "center",
      fontWeight: 300,
      fontSize: "1.5rem",
      [theme.breakpoints.down(1240)]: {
        fontSize: "1rem",
      },
      "& span": {
        fontWeight: "bold",
      },
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
    gap: "5rem",
    marginBottom: "4em",
    alignItems: "center",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      alignItems: "center",
      gap: "0",
    },
    "& img": {
      maxWidth: "30rem",
      width: "100%",
    },
    "& >div": {
      width: "21rem",
      fontWeight: 300,
      fontSize: "1.5rem",
      [theme.breakpoints.down(1240)]: {
        width: "19rem",
        fontSize: "1rem",
        textAlign: "center",
      },
      "& span": {
        fontWeight: "bold",
      },
    },
  },
}));
